

export class flashTexts {
  constructor(container, speed = 1200, mode = 'all') {
    if (container) {
      this.speed = speed
      this.mode = mode
      this.els = Array.from(container.children)
      this.intv = null
      this.init()

    }
  }
  stopCarousel () {
    // console.log('stop flash')
    window.clearInterval(this.intv)
  }

  initCarousel () {

    if (this.els.length > 0) {
      this.index = 0
      // console.log('start flash' , this.els)
      this.intv = window.setInterval(() => {
        this.index ++
        if (this.index >= this.els.length) {
          this.index = 0
        }
        // console.log('loop flash text, index', this.index)
        this.els.forEach((el, index) => {
          if (index !== this.index) {
            el.style.display = 'none'
          } else {
            el.style.display = 'block'
          }
        })
      }, this.speed)
    }
  }

  init () {
    switch (this.mode) {
      case 'mobile':
        if (window.matchMedia('(max-width: 1023px)').matches) {
          this.stopCarousel()
          this.initCarousel()
        } else {
          this.stopCarousel()
        }
        break;
      case 'desktop':
        if (window.matchMedia('(min-width: 1024px)').matches) {
          this.stopCarousel()
          this.initCarousel()
        } else {
          this.stopCarousel()
        }
        break;
      default:
        this.stopCarousel()
        this.initCarousel()
    }
  }

  // add resize behaviour here if needed
  onResize() {
    console.log('resize flashTexts')
    this.init();
  }


}
