const detectableMap = {
  ionic: 'APP_MOBILE_OVS_SFRA',
  ios12: 'OS 12',
  ipad: 'iPad',
  iphone: 'iPhone'
}

export function agentDetect (names) {
  names = typeof names === 'string' ? [names] : names
  return names.every(name => {
    if (!detectableMap.hasOwnProperty(name)) return false
    const mapValue = detectableMap[name]
    if (typeof mapValue === 'string')
        return navigator.userAgent.indexOf(mapValue) >= 0
    return mapValue.every(agentParticle => {
        return navigator.userAgent.indexOf(agentParticle) >= 0
      })
  })
}
