/**
 * Polyfills
 */
import '@babel/polyfill';
import 'custom-event-polyfill';
require('intersection-observer');
var stickyfill = require('stickyfilljs');

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}


/**
 * Libraries
 */
import LazyLoad from "vanilla-lazyload";
var debounce = require('debounce');
import 'dom-fader'


/**
 * Components
 */
// atoms import
import { PillNavigator, CtaTrigger, AnimatedFigure } from './components/atoms'
// basics components import
import {
  HeroCarouselNew,
    FourCards,
    Hamburger,
    StaticCards
} from "./components/basic-components";
// global utilities
import { videoCustomAutoplay } from "./utilitites/video-custom-autoplay";
import { flashTexts } from "./utilitites/flashTexts";
import { autoplayMutedVideoFixIonic } from "./utilitites/autoplayMutedVideoFixIonic";


/**
 * Globals
 */
// all elements that need to implement a resize action
let homepageDynamicElements = window._HCEhomepageDynamicElements  = []
let HCELazyLoadInstance = window._HCELazyLoadInstance = new LazyLoad({
  elements_selector: ".hce-lazy"
});

/**
 * Events Callbacks
 */

let getWindowHeightWidth = () => {
  return {
    w: window.innerWidth,
    h: window.innerHeight,
  }
}

let currentWindowSize = getWindowHeightWidth()

const resizeEvent = function () {
  requestAnimationFrame(() => {
    HCELazyLoadInstance.update()

    let newWindowSize = getWindowHeightWidth()
    let changedHeight = false, changedWidth = false
    if (currentWindowSize.w !== newWindowSize.w) {
      changedWidth = true
    }
    if (currentWindowSize.h !== newWindowSize.h) {
      changedHeight = true
    }
    const allHomePageDynamicElements = [...homepageDynamicElements];
    allHomePageDynamicElements.forEach(el => {
      // console.log('resize', el)
      if (el.onResize && typeof(el.onResize) === "function") {
        el.onResize(changedWidth, changedHeight)
      }
    })
  })
};
const loadedEvent = function(event) {

  // push into observables any basic instance that has to interact with special components
  let observables = []

  new PillNavigator()

  for (let el of document.querySelectorAll(`.hero-carousel-new`)) {
    homepageDynamicElements.push(new HeroCarouselNew(el))
  }


  for (let el of document.querySelectorAll(`.party-season-desktop__texts`)) {
    homepageDynamicElements.push(new flashTexts(el, 700, 'desktop'))
  }
  for (let el of document.querySelectorAll(`.four-cards`)) {
    homepageDynamicElements.push(new FourCards(el))
  }
  for (let el of document.querySelectorAll(`.header`)) {
    homepageDynamicElements.push(new Hamburger(el))
  }
  for (let el of document.querySelectorAll(`.static-cards`)) {
    homepageDynamicElements.push(new StaticCards(el))
  }

  // setup autoplay fix
  const audioVideos = document.querySelectorAll('.video-toBeFixed')

  audioVideos.forEach(vidContainer => {
    videoCustomAutoplay(vidContainer).then(() => {
      console.log('fulfilled')
    })
  })

  autoplayMutedVideoFixIonic()


};

/**
 * Init
 */
window.addEventListener('DOMContentLoaded', loadedEvent)
window.addEventListener('resize', debounce(resizeEvent, 250));
