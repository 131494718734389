import { agentDetect } from "./ua-detector";

export function autoplayMutedVideoFixIonic () {
  document.querySelectorAll('video.toBeFixedIonic').forEach(el => {
    if (!agentDetect('ionic')) {
      el.setAttribute('autoplay', '');
      el.setAttribute('playsinline', '');
      el.play();
    }
  })
}