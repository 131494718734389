export function ctaOverlaySetup() {
  const overlayTriggers = document.querySelectorAll('.hce-btn.cta.activate-overlay');
  const overlayClass = 'cta-overlay-opened'
  overlayTriggers.forEach(trigger => {

    const additionalOverlayClass = trigger.className.split(' ').filter(className => className.indexOf('overlay-id-') >= 0)[0];

    // each time we find a trigger we look for its closest parent content-card or a parent with class cta-overlay-parent
    const closestContentCard = (function closestContentCardRecursion (trigger) {
      if (trigger.parentNode === document.body) {
        return false
      }
      if (trigger.parentNode.classList.contains('content-card') || trigger.parentNode.classList.contains('cta-overlay-parent')) {
        return trigger.parentNode
      }
      return closestContentCardRecursion(trigger.parentNode)
    })(trigger)

    // every time the trigger gets clicked, it adds and remove showing classes

    if (closestContentCard) {
      const additionalClass = typeof additionalOverlayClass !== 'undefined' ? '.' + additionalOverlayClass : ''
      const lookUpClass = `.hce-btn.show-on-overlay${additionalClass}`
      const ctasToBeShown = closestContentCard.querySelectorAll(lookUpClass)

      const ctasToBeShownContainer = document.createElement('div')
      ctasToBeShownContainer.classList.add('ctas-to-be-shown')

      if (typeof additionalOverlayClass !== 'undefined') {
        ctasToBeShownContainer.classList.add(additionalOverlayClass)
      }

      // quickfix 6 ctas
      if (ctasToBeShown.length === 6) {
        ctasToBeShownContainer.classList.add('ctas-to-be-shown-6')
      }
      // quickfix 8 ctas
      if (ctasToBeShown.length === 8) {
        ctasToBeShownContainer.classList.add('ctas-to-be-shown-8')
      }

      const closeButton = document.createElement('div')
      closeButton.classList.add('close-button')

      closeButton.addEventListener('click', (e) => {
        e.stopPropagation()
        closestContentCard.classList.remove(overlayClass)
        requestAnimationFrame(() => {
          closestContentCard.classList.add(`${overlayClass}-tobe`)
          if (typeof additionalOverlayClass !== 'undefined') {
            window.setTimeout(() => {
              closestContentCard.classList.remove(additionalOverlayClass)
            }, 450)
          }
        })
      });
      ctasToBeShownContainer.appendChild(closeButton)
      ctasToBeShown.forEach(cta => {
        const clone = cta.cloneNode(true)
        clone.classList.remove('hidden-mobile')
        clone.classList.remove('hidden-desktop')
        ctasToBeShownContainer.appendChild(clone)
      })
      if( closestContentCard.dataset['overlaytitles'] ) {
        console.warn('has titles')
        const overlayTitles = document.createElement('div')
        overlayTitles.classList.add('overlayTitles')
        let titles = JSON.parse(closestContentCard.dataset['overlaytitles'])
        titles.forEach(title => {
          const overlayTitle = document.createElement('div')
          overlayTitle.classList.add('overlayTitles__title')
          overlayTitle.innerText = title
          overlayTitles.appendChild(overlayTitle)
        })
        ctasToBeShownContainer.appendChild(overlayTitles)
      } else {
        console.warn('DS', closestContentCard.dataset)
      }

      closestContentCard.appendChild(ctasToBeShownContainer)

      closestContentCard.classList.add(`${overlayClass}-tobe`)
      trigger.addEventListener('click', (e) => {
        e.stopPropagation()
        e.preventDefault()
        if (closestContentCard.classList.contains(overlayClass)) {
          closestContentCard.classList.remove(overlayClass)
          requestAnimationFrame(() => {
            closestContentCard.classList.add(`${overlayClass}-tobe`)
          })
        } else {
          closestContentCard.classList.remove(`${overlayClass}-tobe`)
          if (typeof additionalOverlayClass !== 'undefined') {
            closestContentCard.classList.add(additionalOverlayClass)
          }
          requestAnimationFrame(() => {
            closestContentCard.classList.add(overlayClass)
          })
        }
      })
    } else {
      console.log('could not find opener for button: ', trigger)
    }
  })
}
