import ScrollMagic from 'scrollmagic'
import scrollToElement from 'scroll-to-element'
import { agentDetect } from "../../utilitites/ua-detector";

export class PillNavigator {
  constructor() {
    this.pill = document.querySelector('.pill-navigator')
    if (this.pill == null) return
    this.isIonic = agentDetect(['ios12', 'ionic'])
    this.currentSection = 0
    this.forcefulIndex = -1
    this.lookForSections()
    this.buildPillHTML()
    this.setupPillHighlighter()
    this.setupScrollMagic()
    if (this.isIonic) {
      this.scrollMagicIonicFix()
    }
  }

  scrollMagicIonicFix() {
    setInterval(() => {
      this.SMController.update()
    }, 16)
  }

  lookForSections() {
    this.sections = []
    document.querySelectorAll('[data-section-title]').forEach(el => this.sections.push(el))
  }

  buildPillHTML() {
    this.pillSections = []
    // const scrollTypeIs = () => agentDetect(['ionic', 'ios12']) ? 'native' : 'default'
    if (this.isIonic) {
      this.buildPillWithAnchors()
    } else {
      this.buildPillWithDivs()

    }
  }

  // for now this is unused, it was a test for non-scroll-reliable solutions
  buildPillWithAnchors() {
    // adding anchors to sectiontitles
    this.sections.forEach(section => {
      section.style.position = 'relative'
      const isMobile = window.matchMedia('(max-width: 1023px)').matches
      const offset = isMobile ? -70 : -120
      const sectionAnchor = document.createElement('a')
      sectionAnchor.style.position = 'absolute'
      sectionAnchor.style.height = '1px'
      sectionAnchor.style.width = '100%'
      sectionAnchor.style.top = `${offset}px`
      sectionAnchor.style.left = 0
      sectionAnchor.setAttribute('id', section.dataset.sectionTitle)
      sectionAnchor.style.opacity = 0
      sectionAnchor.classList.add('section-anchor-ionic')
      section.appendChild(sectionAnchor)
    })


    // building pill html from anchors
    this.sections.forEach((el, idx) => {
      const section = document.createElement('a')
      section.classList.add('pill-navigator__section')
      section.innerText = el.dataset.sectionTitle
      section.href = '#' + el.dataset.sectionTitle
      section.dataset.index = idx
      section.addEventListener('click', e => {
        this.forcefulIndex = +e.target.dataset.index
      })
      this.pill.appendChild(section)
      this.pillSections.push(section)
    })
  }

  buildPillWithDivs() {

    this.sections.forEach(el => {
      const section = document.createElement('div')
      section.classList.add('pill-navigator__section')
      section.innerText = el.dataset.sectionTitle
      section.dataset.section = el.dataset.sectionTitle
      section.addEventListener('click', this.pillSectionClickHandler.bind(this))
      this.pill.appendChild(section)
      this.pillSections.push(section)
    })
  }

  setupPillHighlighter() {
    const highlighter = document.createElement('div')
    highlighter.classList.add('pill-navigator__highlighter')
    highlighter.style.width = `calc(${100/this.sections.length}% - 10px)`;
    this.pill.appendChild(highlighter)
    this.highlighter = highlighter
  }

  highlightSection (sectionIndex) {
    if (sectionIndex === -1) return
    const sectionWidth = 100/this.sections.length
    this.highlighter.style.left = `calc(${sectionIndex * sectionWidth}% + 5px)`
    this.highlighter.classList.add('active')
    this.pillSections.forEach((el, index) => {
      if (index === sectionIndex) {
        el.classList.add('active')
        return
      }
      el.classList.remove('active')
    })
  }

  setupScrollMagic() {
    this.SMController = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: 0.2
      }
    })

    this.sections.forEach(el => {
      const target = this.isIonic ? el.querySelector('.section-anchor-ionic') : el
      new ScrollMagic.Scene({
        triggerElement: target,
      })
        .on('enter leave', this.scrollMagicEventHandler.bind(this))
        .addTo(this.SMController)
    })
  }

  getSectionIndex (sectionName) {
    return this.sections.map(el => el.dataset.sectionTitle).indexOf(sectionName)
  }

  scrollMagicEventHandler (e) {
    const target = this.isIonic ? e.target.triggerElement().parentNode : e.target.triggerElement()
    const sectionName = target.dataset.sectionTitle
    const sectionIndex = this.getSectionIndex(sectionName)


    // forcing index in anchor based scrolling
    if (this.forcefulIndex !== -1) {
      setTimeout(() => {
        this.pill.classList.add('active')
        this.highlightSection(this.forcefulIndex)
        this.forcefulIndex = -1
      }, 10)
      return
    }

    if (e.type === 'enter') {
      this.pill.classList.add('active')
      this.highlightSection(sectionIndex)
    } else if (e.type === 'leave' && sectionIndex === 0) {
      this.pill.classList.remove('active')
      this.highlighter.classList.remove('active')
      this.pill.querySelector('.pill-navigator__section.active').classList.remove('active')
    } else {
      this.highlightSection(sectionIndex - 1)
    }
  }

  pillSectionClickHandler (e) {
    const isMobile = window.matchMedia('(max-width: 1023px)').matches
    const index = this.pillSections.indexOf(e.target)
    let responsiveOffset = (px, vp) => {
      const vw = px * 100 / vp
      const currentWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      return vw * currentWidth / 100
    }
    const offset = isMobile ? -1*responsiveOffset(70, 375) : -1*responsiveOffset(70,1440)
    const duration = 1250
    console.log('offset', offset)

    scrollToElement(this.sections[index], {
      offset: offset,
      ease: 'out-cube',
      duration: duration
    })

  }
}
