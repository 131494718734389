import Swiper from 'swiper/js/swiper.min'
import index from "can-autoplay";

export class FourCards {
    constructor(el) {
        this.el = el;
        this.fourImages = el.querySelectorAll('.four-cards__fourImages');

        this.mobileDetection();
        this.swipers = [];
        this.initSwiper();
    }


    initSwiper() {
        if (this.swipers.length) {
            this.swipers.forEach(sw => sw.destroy())
            this.swipers = []
        }
        this.swiperConfig = {
            loop: false,
            slidesPerView: 'auto',
            spaceBetween: 8,
            1024: {
                spaceBetween: 0,
            }
        }
        console.log('here', this.fourImages);
        //se siamo su mobile inizializza lo swiper
        if(this.mediaObj.matches) {
            this.fourImages.forEach((images, index) => {
                this.swipers[index] = new Swiper( images, this.swiperConfig )
            })
        }
    }

    mobileDetection() {
        this.mediaObj = window.matchMedia('(max-width: 1023px)')
    }
    // add resize behaviour here if needed
    onResize() {
        this.initSwiper();
    }
}
