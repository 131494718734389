import screenfull from 'screenfull'
import { agentDetect } from "./ua-detector";


export class ActivateFullScreenVideoElement {
  constructor (cta, element, video = false, fade = false, fullscreen = true) {
    this.cta = cta
    this.element = element
    this.hasVideo = !!video
    this.video = video
    this.fade = fade
    this.fullscreen = fullscreen
    this.isIph = agentDetect('iphone')
    this.isIpad = agentDetect( 'ipad')
    this.isIphone = this.isIph || this.isIpad
    this.cta.addEventListener('click', this._elementToActive.bind(this))
    this.videoPlayCallbacks = []
    this.videoPauseCallbacks = []
    this.isActive = false

    if (!this.isIph) {
      this._appendCloseButton()
    }

    if (this.isIphone) {
      this.video.addEventListener('pause', () => {
        this._disableFullscreen()
      })
    }

    this._setupEvents()
  }

  _setupEvents() {
    if (this.hasVideo) {
      this.video.addEventListener('ended', this._elementToNormal.bind(this))
    }
  }

  _elementToActive (e) {
    e.stopPropagation()
    e.preventDefault()
    this.isActive = true
    // todo refactoring
    if (this.fade && this.fullscreen) {
      this.element.fadeIn(0).then(() => {
        this._enableFullscreen()
      })
    } else if (this.fullscreen){
      this._enableFullscreen()
    } else {
      if (this.fade) {
        this.element.fadeIn(0).then(() => {
          this.playVideo()
        })
      } else {
        this.playVideo()
      }
    }
  }

  _elementToNormal (e) {
    e.stopPropagation()
    this.isActive = false
    if (this.fullscreen) {
      this._disableFullscreen()
    } else if (this.fade) {
      this.element.fadeOut(0).then(() => {
        this.pauseVideo()
      })
    } else {
      this.pauseVideo()
    }
  }

  onVideoPlay(callback) {
    this.videoPlayCallbacks.push(callback)
  }

  onVideoPause(callback) {
    this.videoPauseCallbacks.push(callback)
  }

  triggerVideoPlay() {
    this.videoPlayCallbacks.forEach(callback => {
      callback()
    })
  }

  triggerVideoPause() {
    this.videoPauseCallbacks.forEach(callback => {
      callback()
    })
  }

  _enableFullscreen() {
    if (this.isIphone) {
      this.element.classList.add('iphone-fullscreen')
      if (this.hasVideo) this.playVideo()
    } else {
      if (screenfull.isEnabled) {
        screenfull.request(this.element).then(() => {
          if (this.hasVideo) this.playVideo()
        })
      }
    }
  }

  _disableFullscreen() {
    if (this.isIphone) {
      if (this.fade) this.element.fadeOut(0)
      this.element.classList.remove('iphone-fullscreen')
      this.triggerVideoPause()
    } else {
      screenfull.exit().then(() => {
        if (this.hasVideo) this.pauseVideo()
        if (this.fade) this.element.fadeOut(0)
        this.triggerVideoPause()
      })
    }
  }

  playVideo () {
    this.video.play()
    if (!this.isIph) this.closeButton.classList.add('visible')
    this.triggerVideoPlay()
  }

  pauseVideo () {
    this.video.pause()
    if (!this.isIph) this.closeButton.classList.remove('visible')
    this.triggerVideoPause()
  }

  _appendCloseButton() {
    const xSvg = `<svg id="X" xmlns="http://www.w3.org/2000/svg" width="51" height="52" viewBox="0 0 51 52">
    <defs>
      <style>
        .cls-1 {
          fill: none;
          stroke: #000;
          stroke-width: 1.5px;
          fill-rule: evenodd;
        }
      </style>
    </defs>
    <filter id="dropshadow" height="130%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
      <feOffset dx="2" dy="2" result="offsetblur"/> 
      <feComponentTransfer>
        <feFuncA type="linear" slope="0.5"/> 
      </feComponentTransfer>
      <feMerge> 
        <feMergeNode/> 
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
    <path id="Forma_2" data-name="Forma 2" class="cls-1" d="M0.524,50.224L49.212,1.538" style="filter:url(#dropshadow)"/>
    <path id="Forma_2_copia" data-name="Forma 2 copia" class="cls-1" d="M49.212,50.224L0.524,1.538" style="filter:url(#dropshadow)"/>
    </svg>`

    const closeButton = document.createElement('div')
    closeButton.classList.add('fullscreen-close')
    closeButton.innerHTML = xSvg
    closeButton.addEventListener('click', this._elementToNormal.bind(this))
    this.closeButton = closeButton
    this.element.appendChild(closeButton)
  }
}
