export const mobileSwiperConfig = {
  loop: true,
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: 0,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  init: false,
  navigation: {
    prevEl: '.hero-carousel__mobile__prev',
    nextEl: '.hero-carousel__mobile__next',
  }
};

export const perc80mobileSwiperConfig = {
  loop: true,
  slidesPerView: 1.2,
  centeredSlides: true,
  spaceBetween: 10,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  init: false,
  navigation: {
    prevEl: '.hero-carousel__mobile__prev',
    nextEl: '.hero-carousel__mobile__next',
  }
}
export const perc80desktopSwiperConfig = {
  loop: true,
  slidesPerView: 1.1765,
  centeredSlides: true,
  spaceBetween: 20,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  init: false,
  navigation: {
    prevEl: '.hero-carousel__desktop__prev',
    nextEl: '.hero-carousel__desktop__next'
  }
}

export const desktopSwiperConfig = {
  loop: true,
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: 0,
  speed: 700,
  autoplay: {
    delay: 7000,
    disableOnInteraction: false
  },
  init: false,
  navigation: {
    prevEl: '.hero-carousel__desktop__prev',
    nextEl: '.hero-carousel__desktop__next'
  }
}
