export class AnimatedFigure {
  constructor(el) {

    this.el = el
    this._intersectionObserver = null
    this._observerOptions = {
      threshold: 0.4,
      rootMargin: '0px'
    }

    this._startDashOffset = 157 // hard-coded see CSS
    this._pie = this.el.querySelector('.animated-figure__pie')
    this._circle = this.el.querySelector('.animated-figure__pie svg circle')
    this._figure = this.el.querySelector('.animated-figure__figureShown')

    this.hasPlayed = false


    this._startDashOffsetSegments = 314 // hard-coded see CSS
    this._segments = this.el.querySelector('.animated-figure__segments')
    if (this._segments) {
      this._segmentsCircles = this._segments.querySelectorAll('circle')
      console.log('has segments', this._segmentsCircles)
      // look for elements to animate in parent
      this._figureEls = this.el.parentElement.querySelectorAll('.cake-animation__el')
      console.warn('figEl', this._figureEls)
    }


    this._setupProperties()
    this._setupEvents()
  }

  _setupProperties() {
    //  console.log('setup props', this.el)
    this._intersectionObserver = null
    this._observerOptions = {
      threshold: 0.4,
      rootMargin: '0px'
    }
    this._animationStart = parseInt(this.el.dataset.startfigure || 0)
    this._animationTarget = (this.el.dataset.targetfigure || 0)
    this._animationTiming = (this.el.dataset.animationtiming || 3000)
    this._animateCircleOnly = (this.el.dataset.animatecircleonly === 'true' || false)
    this._replayEveryTime = (this.el.dataset.replay === 'true' || false)

  }

  _setupEvents() {
    //  console.log('setup events', this.el)
    this._intersectionObserver = new IntersectionObserver(this._intersectionHandler.bind(this), this._observerOptions)
    this._intersectionObserver.observe(this.el)

  }

  _calculateCircleDasharray(target = this._animationTarget, isSegments = false) {
    let total = this._startDashOffset
    if (isSegments) {
      total = this._startDashOffsetSegments
    }
    let dash = total - target * total / 100
    //  console.log('anim target dash', total, target, dash)
    return dash
  }

  _resetCircle() {
    this._setCircleTransitionDuration(0)
    this._circle.style.strokeDashoffset = this._startDashOffset
  }

  _resetSegments() {
    this._figureEls[this._figureEls.length-1].style.opacity = 1
    this._setSegmentsTransitionDuration(0)
    this._segmentsCircles.forEach(el => {
      el.style.strokeDashoffset = this._startDashOffset
    })
  }

  _setCircleTransitionDuration(val) {
    this._circle.style.transitionDuration = `${val}s`
  }

  _setSegmentsTransitionDuration(val) {
    this._segmentsCircles.forEach(el => {
      el.style.transitionDuration = `${val}s`
    })

  }


  _playAnimation() {
    if (this._pie) {
      this._resetCircle()
      /*window.requestAnimationFrame(()=> {
        this._setCircleTransitionDuration(this._animationTiming/1000);
        this._circle.style.strokeDashoffset = this._calculateCircleDasharray();
      });*/

    }
    if (this._segments) {
      this._resetSegments()
      /*window.requestAnimationFrame(()=> {
        this._setCircleTransitionDuration(this._animationTiming/1000);
        this._circle.style.strokeDashoffset = this._calculateCircleDasharray();
      });*/

    }
    this._animateValue(this._animationStart, this._animationTarget, this._animationTiming)
  }

  _intersectionHandler(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (this._replayEveryTime) {
          this._playAnimation()
        } else if (!this.hasPlayed) {
          this._playAnimation()
          //  console.log('play figure animation')
          this.hasPlayed = true
        }
      } else {
        // //  console.log('exiting')
        // do nothing
      }
    })
  }

  _animateValue(start, end, duration) {
    let startTimestamp = null
    let finished = []
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp
      const progress = Math.min((timestamp - startTimestamp) / duration, 1)
      let currentNumber = Math.floor(progress * (end - start) + start)
      // console.log('n', progress, currentNumber, start, typeof start)
      if (this._pie) {
        this._circle.style.strokeDashoffset = this._calculateCircleDasharray(currentNumber)
      }
      if (this._segments) {
        this._segmentsCircles.forEach((el, index) => {
            // console.warn('ìsegment ', index, currentNumber, el.dataset.to)
            if (currentNumber <= parseInt(el.dataset.to)) {
              let target = this._calculateCircleDasharray(currentNumber, true)
              // console.warn('hasnot finished ', index, currentNumber, el.dataset.to, target)
              finished[index] = false
              el.style.strokeDashoffset = target
              if (currentNumber ===  parseInt(el.dataset.to)) {
                let nextEl = index - 1
                if (nextEl >= 0) {
                  this._figureEls[nextEl].style.opacity = 1
                }
              }
            } else {
              if (finished[index] === false) {
                console.warn('has finished ', index, this._figureEls[index])
                finished[index] = true
              }
            }

          }
        )
      }
      if (!this._animateCircleOnly) {
        this._figure.innerHTML = currentNumber
      }
      if (progress < 1) {
        window.requestAnimationFrame(step)
      }
    }
    window.requestAnimationFrame(step)
  }

  onResize() {
    this._setupProperties()
    this._setupEvents()
  }

}
