/*
Finds an HTML5 video in the el, and makes it play only when it is visible on screen
 */

export class videoIntersectionManager {
  constructor (el) {
    this.el = el;
    this._intersectionObserver = null
    this._observerOptions = {
      threshold: 0.4,
      rootMargin: '0px'
    }
    this._video = null;

    this._setupProperties()
    this._setupEvents()
  }
  _setupProperties () {
    // console.log('setup props', this.el)
    this._video = this.el.querySelector(`video`)
    this._intersectionObserver = null
    this._observerOptions = {
      threshold: 0.4,
      rootMargin: '0px'
    }
  }

  _setupEvents () {
    // console.log('setup events', this.el)
    this._intersectionObserver = new IntersectionObserver(this._intersectionHandler.bind(this), this._observerOptions)
    this._intersectionObserver.observe(this.el)

  }

  _intersectionHandler (entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // console.log('play', this._video)
        this._video.play()
      } else {
        // console.log('pause', this._video)
        this._video.pause()
      }
    })
  }

  onResize () {
    this._setupProperties()
    this._setupEvents()
  }

}
