import Swiper from 'swiper/js/swiper.min'
import { agentDetect } from "../../utilitites/ua-detector";
import {mobileSwiperConfig, desktopSwiperConfig, perc80mobileSwiperConfig, perc80desktopSwiperConfig} from "./hero-carousel/swiper-configurations";
import { ActivateFullScreenVideoElement } from "../../utilitites/activateFullscreenVideoElement";
import {videoIntersectionManager} from "../../utilitites/videoIntersectionManager";

export class HeroCarouselNew {
  constructor (element) {
    /**
     * Ubiquitous properties
     */
    this.el = element
    this.mobileContainer = this.el.querySelector('.hero-carousel__mobile')
    this.desktopContainer = this.el.querySelector('.hero-carousel__desktop')
    this.mobileSwiper = null
    this.desktopSwiper = null
    this.isIonic = agentDetect('ionic')
    this.mediaQuery = window.matchMedia('(max-width: 1023px)')
    this.ctaOverlayOpenClass = 'cta-overlay-opened'

    /**
     * Properties that has to be re-initialized every time
     */
    this._initProperties()
    this._initSwipers()
    this._initUbiquitousEvents()
  }

  /**
   * init object properties, useful for re-initiation
   * @private
   */
  _initProperties () {
    this.currentSwiper = null
    this.currentContainer = null
    this.currentConfig = {}
    this.isMobile = this.mediaQuery.matches

    /**
     * Timeout for slide changing when we are not using default autoplay behaviour of swiper
     * @type {null}
     */
    this.slideCustomTimeout = null

    /**
     * a video without autoplay is currently playing (needs to be stopped when slide is changin)
     * @type {boolean}
     */
    this._videoIsPlaying = false

    /**
     * Video being played when video is playing is set to true
     * @type {HTMLElement}
     * @private
     */
    this._videoBeingPlayed = null

    /**
     * a video with fullscreen is currently playing through our utility
     * @type {boolean}
     */
    this._fullscreenIsPlaying = false

    /**
     * Here we store an object, containing a function reference and an element, so we can
     * remove listeners in case we need to reInitialize the component
     * @type {*[]}
     */
    this.eventHandlers = []
  }


  /**
   * Swipers initialization
   */
  _initSwipers () {
    // Init mobile or desktop swiper based on current viewport and number of slides
    if (this.isMobile) {
      this.currentContainer = this.mobileContainer
      if (this.mobileContainer.querySelectorAll('.swiper-slide').length > 1) {
        this._initMobileSwiper()
        this._initSwiperEvents()
      } else {
        this._setupSingleSlideSwiper()
      }
      this._checkOverlayVideo('mobile')
    } else {
      this.currentContainer = this.desktopContainer

      if (this.desktopContainer.querySelectorAll('.swiper-slide').length > 1) {
        this._initDesktopSwiper()
        this._initSwiperEvents()
      } else {
        this._setupSingleSlideSwiper()
      }
      this._checkOverlayVideo('desktop')
    }
  }

  /**
   * When we are passing through the threshold (def: 1023px) it reinitialize events and swipers
   * @private
   */


  onResize(changedWidth, changedHeight) {
    // only resize on changedWidth, height can change on mobile due to URL bar disappearing
    if (changedWidth) {
      this._removeEvents()
      if(this.currentSwiper) {
        this.currentSwiper.destroy()
      }
      this._initProperties()
      this._initSwipers()
    }
  }

  _initMobileSwiper() {
    /**
     * TODO: handle special cases better
     */
    const config = this.el.classList.contains('perc80') ? perc80mobileSwiperConfig : mobileSwiperConfig
    this.mobileSwiper = new Swiper(this.mobileContainer, config)
    this.currentSwiper = this.mobileSwiper
    this.currentConfig = mobileSwiperConfig
  }

  _initDesktopSwiper() {
    const config = this.el.classList.contains('perc80desktop') ? perc80desktopSwiperConfig : desktopSwiperConfig
    this.desktopSwiper = new Swiper(this.desktopContainer, config)
    this.currentSwiper = this.desktopSwiper
    this.currentConfig = desktopSwiperConfig
  }

  _toggleDesktopNavigation (hide) {
    const prev = '.hero-carousel__desktop__prev'
    const next = '.hero-carousel__desktop__next'
    let prevEls = this.desktopContainer.querySelectorAll(`${prev}, ${next}`)
    console.warn('prevEls', prevEls)
    for (let el of prevEls) {
      if (hide) {
        el.style.display = 'none'
      } else {
        el.style.display = 'block'
      }
    }
  }
  _toggleMobileNavigation (hide) {
    const prev = '.hero-carousel__mobile__prev'
    const next = '.hero-carousel__mobile__next'
    const progress = '.hero-carousel__progress'
    let prevEls = this.mobileContainer.querySelectorAll(`${prev}, ${next}`)
    console.warn('prevEls', prevEls)
    for (let el of prevEls) {
      if (hide) {
        el.style.display = 'none'
      } else {
        el.style.display = 'block'
      }
    }
    let progBar = this.mobileContainer.querySelectorAll(progress)
    console.warn('progBar', progBar)
    for (let el of progBar) {
      if (hide) {
        el.style.display = 'none'
      } else {
        el.style.display = 'flex'
      }
    }
  }

  _checkOverlayVideo(type) {
    let ctas
    const ctaSelector = '.hero-carousel__overlay-video-cta a'
    const videoLayerSelector = '.hero-carousel__overlay-video-layer'
    const videoLayerShowClass = 'hero-carousel__overlay-video-layer--show'
    const videoElementSelector = '.hero-carousel__overlay-video'
    const closeVideoSelector = '.hero-carousel__overlay-video-close'
    const slideSelector = '.hero-carousel__element'
    if (type === 'desktop') {
      ctas = this.desktopContainer.querySelectorAll(ctaSelector)
    } else {
      ctas = this.mobileContainer.querySelectorAll(ctaSelector)
    }
    for (let cta of ctas) {
      console.warn('found ctas', cta)
      let alreadyActivatedClass = 'hero-carousel-overlay-video-activated'
      if (!cta.classList.contains(alreadyActivatedClass)) {
        cta.classList.add(alreadyActivatedClass)
        let thisSlide = cta.closest(slideSelector)
        let videoLayer = thisSlide.querySelector(videoLayerSelector)
        let video = videoLayer.querySelector(videoElementSelector)
        // setup click action
        cta.addEventListener('click', (e) => {
          e.preventDefault()
          // stop swiper carousel, if present
          if (this.currentSwiper) {
            console.warn('stopping video autoplay')
            this.currentSwiper.autoplay.stop()
            this._toggleDesktopNavigation(true)
            this._toggleMobileNavigation(true)
            if (this.slideHasVideoAutoplay) {
              // pause autoplay video in slide
              console.warn('slideHasVideoAutoplay')
              this.currentSlideVideo.pause()
            }
          }

          // show video layer
          videoLayer.classList.add(videoLayerShowClass)

          // reset video to start
          video.currentTime = 0
          // play video
          video.play()


        })
        if (type !== 'desktop') {
          // for ios
          video.addEventListener('webkitendfullscreen',  (e) => {

            // handle end full screen
            videoLayer.classList.remove(videoLayerShowClass)
            // restart swiper carousel, if present
            if (this.currentSwiper) {
              this.currentSwiper.autoplay.start()

              if (this.slideHasVideoAutoplay) {
                // restart autoplay video in slide
                this.currentSlideVideo.play()
              }
              this._toggleMobileNavigation(false)
            }
          });
        }

        // setup close action
        let closeButton = thisSlide.querySelector(closeVideoSelector)
        closeButton.addEventListener('click', (e) => {
          e.preventDefault()
          video.pause()
          videoLayer.classList.remove(videoLayerShowClass)
          // restart swiper carousel, if present
          if (this.currentSwiper) {
            this.currentSwiper.autoplay.start()

            if (this.slideHasVideoAutoplay) {
              // restart autoplay video in slide
              this.currentSlideVideo.play()
            }
            this._toggleDesktopNavigation(false)
            this._toggleMobileNavigation(false)
          }
        })
      }

    }
  }

  _setupSingleSlideSwiper () {
    if (this.currentContainer.querySelectorAll('.swiper-slide').length === 0) {
      this.noSlidesSwiper = true
    }
    this.singleSlideSwiper = true
    this._playVideoIfPresent()
    this._loopVideoIfAutoplay()
  }

  /*
  # Swiper event handlers
  */
  _initSwiperEvents () {
    let s = this.currentSwiper

    s.on('init', this._onSwiperInit.bind(this))
    s.on('slideChange', this._onSwiperSlideChange.bind(this))
    s.on('autoplay', this._onSwiperAutoplay.bind(this))
    s.on('autoplayStart', this._onSwiperAutoplayStart.bind(this))
    s.on('autoplayStop', this._onSwiperAutoplayStop.bind(this))

    s.init()
  }

  /**
   * Swiper init handler
   * @private
   */
  _onSwiperInit () {
    this._updateAutoplay()
    if (this.swiperHasProgressBar) {
      this._setupProgressBar()
      this._playProgressBar()
    }
    window._HCELazyLoadInstance.update()

    this._playVideoIfPresent()

    this._initCollateralEvents()
  }

  /**
   * Swiper slide change handlers
   * @private
   */
  _onSwiperSlideChange () {
    this._updateAutoplay()
    this._pauseLastVideoIfPresent()
    this._playVideoIfPresent()

    if (this.swiperHasProgressBar) {
      this._progressBarSlideChange()
    }
  }

  _onSwiperAutoplay () {
  }

  _onSwiperAutoplayStart () {
    // this._playProgressBar()
  }

  _onSwiperAutoplayStop () {
    if (this.swiperHasProgressBar) {
      this._pauseProgressBar()
    }
  }

  /**
   * Here we initiate events of objects inside the swiper slide that could/should interact
   * with the swiper itself
   * @private
   */
  _initCollateralEvents () {
    this.currentContainer.querySelectorAll('.swiper-slide').forEach(slide => {

      // pausing autoplay when ctaOverlay is getting opened
      for (let overlayTrigger of slide.querySelectorAll('.activate-overlay')) {
        this._addEvent('click', overlayTrigger, this._overlayTriggerClickHandler.bind(this))
      }
    })
  }

  /**
   * Init events that doesn't interact with swiper/should be initialized even with a single slide
   * @private
   */
  _initUbiquitousEvents () {
    if (this.el.classList.contains('hcn-pauseVideos')) {
      // TODO improve
      console.warn('hasPausevideos')
      this.videoIntersectionManagers = []
      this._videos = this.el.querySelectorAll('.hero-carousel__element[data-video]')
      this._videos.forEach((vid, index) => {
        if (!this.videoIntersectionManagers[index]) {
          this.videoIntersectionManagers[index] = new videoIntersectionManager(vid)
        } else {
          this.videoIntersectionManagers[index].onResize()
        }
      })

    }

    this.currentContainer.querySelectorAll('.swiper-slide').forEach(slide => {
      // playing a video when a cta has play-video class
      for (let playVideoCta of slide.querySelectorAll('.play-video')) {
        this._addEvent('click', playVideoCta, this._playVideoCtaClickHandler.bind(this))
      }

      if (this._checkDataset(slide, 'videoPlay') && !this._checkDataset(slide, 'videoFullscreen')) {
        for (let video of slide.querySelectorAll('video')) {
          this.currentSlideVideo.addEventListener('ended', () => {
            this.videoIsPlaying = false
          })
          this.currentSlideVideo.addEventListener('pause', () => {
            this.videoIsPlaying = false
          })
        }
      }

      if (this._checkDataset(slide, 'videoFullscreen')) {
        for (let activateFullscreenCta of slide.querySelectorAll('.activate-fullscreen')) {
          const fullScreenInstance = new ActivateFullScreenVideoElement(
            activateFullscreenCta,
            slide,
            slide.querySelector('video'),
            false,
            true)

          fullScreenInstance.onVideoPause(() => {
            this.currentSlideVideo.currentTime = 0
            this.fullscreenIsPlaying = false
            this._updateAutoplay()
          })

          fullScreenInstance.onVideoPlay(() => {
            this.fullscreenIsPlaying = true
            this._updateAutoplay()
          })
        }
      }
    })
  }

  /**
   * Add and event handler to an element and save the informations in this.eventHandlers
   * @param type
   * @param el
   * @param handler
   * @private
   */
  _addEvent(type, el, handler) {
    this.eventHandlers.push({
      'el': el,
      'type': type,
      'handler': handler
    })
    el.addEventListener(type, handler)
  }

  _overlayTriggerClickHandler () {
    this._pauseAutoplay()
    this.el.classList.add(this.ctaOverlayOpenClass)
    // search for created overlay close buttons and attach events
    // sliding next when overlay gets closed
    requestAnimationFrame(() => {
      for (let overlayClose of this.currentSlide.querySelectorAll('.close-button')) {
        if (!overlayClose.classList.contains('hero-carousel-event-init')) {
          overlayClose.classList.add('hero-carousel-event-init')
          this._addEvent('click', overlayClose, this._overlayCloseClickHandler.bind(this))
        }
      }
    })
  }

  _overlayCloseClickHandler () {

    this._resumeAutoplay()
    this.el.classList.remove(this.ctaOverlayOpenClass)

    // this.currentSwiper.slideNext()
  }

  _playVideoCtaClickHandler (e) {
    e.preventDefault()
    this._playVideoHandler()
  }

  /**
   * Remove bound events
   * @private
   */
  _removeEvents () {
    for (let {el, type, handler} of this.eventHandlers) {
      el.removeEventListener(type, handler)
    }
  }

  /*
  # Autoplay stuff
   */
  /**
   * Check if this slide has a custom autoplay and work around it
   * @private
   */
  _updateAutoplay () {
    /** preemptively stop autoplay and reset any timeout */
    this._pauseAutoplay()

    /** any special case? */

    // If slide has fullscreen, the activatefullscreen utility will handle autoplay
    if (this.fullscreenIsPlaying) {
      return
    }

    if (this.slideHasCustomDelay && !this.videoIsPlaying && !this.fullscreenIsPlaying) {
      this.slideCustomTimeout = setTimeout(() => {
        this.currentSwiper.slideNext()
      }, this.currentAutoplayDelay)
      return
    }

    if (this.slideHasVideoAutoplay || this.videoIsPlaying) {
      // console.log(this.videoIsPlaying, this.currentSlideVideo, this.currentSlide)
      const videoPlaying = this.videoIsPlaying ? this._videoBeingPlayed : this.currentSlideVideo
      if( videoPlaying) {
        videoPlaying.addEventListener('ended', e => {
          if (this._videoBeingPlayed != null) this._videoBeingPlayed = null
          e.target.currentTime = 0
          e.target.pause()
          this.currentSwiper.slideNext()
        }, { once: true })
      }
      return
    }

    /** resume autoplay if there are no special cases */
    this._resumeAutoplay()

  }

  /**
   * Pause autoplay and remove any timer/event that would eventually automatically restart it
   * @private
   */
  _pauseAutoplay () {
    this.currentSwiper.autoplay.stop()

    /** reset any timeout (needed if we have slided manually) */
    clearTimeout(this.slideCustomTimeout)
  }

  /**
   * Resume normal autoplay behaviour of swiper
   * @private
   */
  _resumeAutoplay () {
    if (!this.currentSwiper.autoplay.running) this.currentSwiper.autoplay.start()
  }

  /*
  # Video handling
   */
  /**
   * Play video in current slide if it should be autoplayed
   * @private
   */
  _playVideoIfPresent () {
    if (this.isIonic) return
    if (this.slideHasVideoAutoplay) {
      this.currentSlideVideo.play()
    }
  }

  /**
   * Loop video if single slide mode
   * @private
   */
  _loopVideoIfAutoplay () {
    if (this.isIonic) return
    if (this.slideHasVideoAutoplay) {
      this.currentSlideVideo.setAttribute('autoplay', true)
      this.currentSlideVideo.setAttribute('loop', true)
    }
  }

  /**
   * On slide change, pause any video that is still playing
   * @private
   */
  _pauseLastVideoIfPresent () {
    this.videoIsPlaying = false
    this.currentContainer.querySelectorAll('.swiper-slide').forEach(slide => {
      const slideIndex = parseInt(slide.dataset.swiperSlideIndex)
      if (slideIndex !== this.currentSwiper.activeIndex && !this._checkDataset(slide, 'videoFullscreen')) {
        const video = slide.querySelector('video')
        if (video) {
          if ( video.readyState === 4 ) {
            video.currentTime = 0
            video.pause()
          }
        }
      }
    })
  }

  /**
   * Play a non-autoplay video when the user has interacted
   */
  _playVideoHandler () {
    if (!this.videoIsFullscreen) {
      this.currentSlideVideo.play()
      this.videoIsPlaying = true
      if (!this.singleSlideSwiper) {
        this._updateAutoplay()
        this._playProgressBar()
      }
    }
  }

  /*
   # Progress bar
   */
  /**
   * Setup class variables for progress bar
   * @private
   */
  _setupProgressBar () {
    this.progressBarContainer = this.currentContainer.querySelector('.hero-carousel__progress')
    this.progressBarSegments = this.progressBarContainer.querySelectorAll('.hero-carousel__progress__segment')
    this.progressBarInterval = null
    this.progressBarProgress = 0
  }

  /**
   * Draws the progress bar
   * @param currentSlideIndex
   * @private
   */
  _updateProgressBar (currentSlideIndex) {
    if (this.progressBarProgress >= 100) return

    this.progressBarProgress++

    this.progressBarSegments.forEach((segment, index) => {
      if (index < currentSlideIndex) {
        segment.style.width = '100%'
        segment.style.transition = 'none'
        return
      }
      if ( index > currentSlideIndex ) {
        segment.style.width = '0'
        segment.style.transition = 'none'
        return
      }

      segment.style.transition = ''
      segment.style.width = `${this.progressBarProgress}%`
    })
  }

  /**
   * Event handler for slide change, handles progress bar behaviour on slide change
   * @private
   */
  _progressBarSlideChange() {
    this._playProgressBar()
  }

  /**
   * resets progress bar interval
   * @private
   */
  _pauseProgressBar () {
    this.progressBarProgress = 0
    clearInterval(this.progressBarInterval)
  }

  /**
   * Reset and restart progress bar interval
   * @private
   */
  _playProgressBar () {
    if (!this.swiperHasProgressBar) return
    this._pauseProgressBar()
    const duration = this.currentAutoplayDelay
    this.progressBarInterval = setInterval(this._updateProgressBar.bind(this, this.currentSlideIndex), duration/100)
  }

  /*
  # Utilities
   */
  _checkDataset (element, data) {
    return typeof element.dataset[data] !== "undefined"
  }

  /**
   * Shows/hide elements that should appear/disappear based on the fact that a video is playing
   * @param value
   * @private
   */
  _videoIsPlayingVisibilityToggle (value) {
    this.currentSlide.querySelectorAll('.hidden-while-playing').forEach(el => {
      if (value) {
        el.fadeOut(300, 'easeOut')
      } else {
        el.fadeIn(300, 'easeOut')
      }
    })

    this.currentSlide.querySelectorAll('.shown-while-playing').forEach(el => {
      if (value) {
        el.fadeIn(300, 'easeOut')
      } else {
        el.fadeOut(300, 'easeOut')
      }
    })
  }

  /*
  # Getters and Setters
   */

  /**
   * Returns if current swiper has a progress bar
   * @returns {boolean}
   */
  get swiperHasProgressBar () {
    return this._checkDataset(this.currentContainer, 'progressBar')
  }

  /**
   * Checks if heroCarousel has a single slide (no swiper)
   * @returns {*}
   */
  get singleSlideSwiper () {
    return typeof this._singleSlideSwiper === 'undefined' ? false : this._singleSlideSwiper
  }

  set singleSlideSwiper (value) {
    if (value) {
      this.el.classList.add('hero-carousel--no-swiper')
    } else {
      this.el.classList.remove('hero-carousel--no-swiper')
    }

    this._singleSlideSwiper = value
  }

  get videoIsPlaying () {
    return this._videoIsPlaying
  }

  set videoIsPlaying (value) {
    this._videoIsPlayingVisibilityToggle(value)
    if (value) {
      this._videoBeingPlayed = this.currentSlideVideo
    }

    this._videoIsPlaying = value
  }

  get fullscreenIsPlaying () {
    return this._fullscreenIsPlaying
  }

  set fullscreenIsPlaying (value) {
    this._videoIsPlayingVisibilityToggle(value)

    this._fullscreenIsPlaying = value
  }

  /**
   * Returns current slide Dom Element
   * @returns {HTMLElement}
   */
  get currentSlide () {
    if (this.noSlidesSwiper) return document.createElement('div') // dummy empty element
    if (this.singleSlideSwiper) return this.currentContainer.querySelector('.swiper-slide')
    return Array.from(this.currentContainer.querySelectorAll('.swiper-slide'))[this.currentSwiper.activeIndex]
  }

  /**
   * Returns current slide real index
   * @returns {number}
   */
  get currentSlideIndex () {
    return parseInt(this.currentSlide.dataset.swiperSlideIndex)
  }

  /**
   * Gets number of slides (without duplicates)
   * @returns {number}
   */
  get slidesNumber () {
    return Array.from(this.currentContainer.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)')).length
  }

  /**
   * check if this slide has a video
   * @returns {boolean}
   */
  get slideHasVideo () {
    return this._checkDataset(this.currentSlide, 'video')
  }

  /**
   * check if video should go fullscreen
   * @returns {boolean}
   */
  get videoIsFullscreen () {
    return this._checkDataset(this.currentSlide, 'video-fullscreen')
  }

  /**
   * Checks if this slide has a custom delay data attribute
   * @returns {boolean}
   */
  get slideHasCustomDelay () {
    return this._checkDataset(this.currentSlide, 'customDelay')
  }

  /**
   * Returns if this slide has a video which should be autoplayed
   * @returns {boolean}
   */
  get slideHasVideoAutoplay () {
    return this._checkDataset(this.currentSlide, 'videoAutoplay')
  }

  /**
   * Returns the time that should pass between this and the next slide
   * (e.g. custom delay, default delay, autoplay video duration etc)
   * @returns {number}
   */
  get currentAutoplayDelay () {
    if (this.slideHasCustomDelay && !this.videoIsPlaying) {
      return parseInt(this.currentSlide.dataset.customDelay)
    }

    if (this.slideHasVideoAutoplay || this.videoIsPlaying) {
      return this.currentSlideVideo.duration
    }

    return this.currentConfig.autoplay.delay
  }

  /**
   * Returns Video HTMLVideoElement of current Slide
   * @returns {*|Element|HTMLVideoElement}
   */
  get currentSlideVideo () {
    return this.currentSlide.querySelector('video')
  }

  /*
  # Public methods
   */

  /**
   * deprecated method, needed for a special component, maybe we should remove it
   */
  pauseDesktopAutoplay() {
    this.desktopSwiper.autoplay.stop()
  }

  /**
   * see above method
   */
  startDesktopAutoplay() {
    this.desktopSwiper.autoplay.start()
  }

  /**
   * Listen to a particular slide changing (subscriber)
   * @param slideNumber
   * @param callback
   */
  subscribeToDesktopSlideOut(slideNumber, callback) {
    if (!!this.desktopSwiper) {
      this.subscribeToSlideOut(slideNumber, callback)
    }
  }

  /**
   * Listen to a particular slide changing, when the slide goes out (subscriber)
   * @param slideNumber
   * @param callback
   */
  subscribeToSlideOut(slideNumber, callback) {
    this.currentSwiper.on('slideChange', () => {
      if (this.currentSwiper.realIndex !== slideNumber) {
        callback()
      }
    })
  }
}
