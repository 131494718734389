import Swiper from 'swiper/js/swiper.min'
import index from "can-autoplay";

export class Hamburger {
    constructor(el) {
        this.el = el;
        this.hamburger = el.querySelector('.hamburger');
        this.container = el.querySelector('.header__container')
        this.containerActive = ('header__container--active')
        this.hamburgerActive = ('is-active')
        this.list = el.querySelector('.header__list-mobile')
        this.listShow = ('header__list-mobile--show')
        this.addHamburger()

    }

    addHamburger() {
        this.hamburger.addEventListener('click', (e) => {
            console.log('there', this.hamburger)
            this.hamburger.classList.toggle(this.hamburgerActive);
            this.container.classList.toggle(this.containerActive);
            this.list.classList.toggle(this.listShow)
        })
    }


    // add resize behaviour here if needed
    onResize() {
    }
}
